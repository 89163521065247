<template>
    <div class="flex flex-col justify-center sm:px-6 lg:px-8 ccss--landing">
        <div class="sm:mx-auto sm:w-full sm:max-w-md text-center ">
            <h2 class="text-center text-3xl font-extrabold">
                {{ $t('page.404') }}
            </h2>
            <a href="/"><button class="mt-10 btn btn-primary ">{{ $t('page.404.button') }}</button></a>
        </div>
    </div>
</template>


<script>
export default {
    name: '404',
}
</script>
